import React, { useEffect } from "react"
import { Link, navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import MobileHeader from '../components/MobileHeader'
import { Button, Row, Col, Table, Media, Card, ListGroup } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import CommonFlex from '../components/commonFlex'
import Footer from '../components/footer'
import BreadCrumb from './../components/breadcrumb'
import { bdstatistics, apiURL, mqStatistics, check } from '../components/census'

import Consult from '../components/consult'

import './../assets/css/about.css'
// 引入此路径，才不会打包失败
// import Swiper from 'swiper'
// import 'swiper/swiper.min.css'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import './../assets/css/navigation.min.css'
import './../assets/css/pagination.min.css'
import './../assets/css/scrollbar.min.css'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const wxData = [
  { url: require('./../images/小助手.png'), title: '扫码添加小助手' },
  { url: require('./../images/公众号.png'), title: '扫码关注公众号' },
  { url: require('./../images/交流社区.png'), title: 'BBS 交流群' },
]


const xmData = [
  {
      title: '全国项目负责人：丁先生',
      url: require('./../images/丁先生.png'),
      desc: '北京'
  },
  {
      title: '华北大区客户经理：李女士',
      url: require('./../images/李女士.png'),
      desc: '黑龙江、吉林、辽宁、河北、山东、安徽、天津'
  },
  {
      title: '华东大区客户经理：徐先生',
      url: require('./../images/徐先生.png'),
      desc: '江苏、上海、浙江'
  },
  {
      title: '华南大区客户经理：黄先生',
      url: require('./../images/黄先生.png'),
      desc: '湖北、湖南、江西、福建、广东、海南'
  },
  {
      title: '西南大区客户经理：王女士',
      url: require('./../images/王女士.png'),
      desc: '四川、重庆、贵州、云南、广西'
  },
  {
      title: '西北大区客户经理：闫先生',
      url: require('./../images/闫先生.png'),
      desc: '陕西、山西、河南、内蒙古、宁夏、甘肃、青海、新疆、西藏'
  }
]

const fzlcData = [ 
  {
    image: require('./../images/beat.jpg'),
    desc: `
      <div class="fzlc-title">2014年</div>
      <div>替代油田组态软件</div>
    `,
    version: 'AIRIOTbeta'
  },
  {
    image: require('./../images/1.0.jpg'),
    desc: `
      <div class="fzlc-title">2017年</div>
      <div>完成xx油田</div>
      <div>采油厂油气生产</div>
      <div>物联网一期项目</div>
    `,
    version: 'AIRIOT1.0'
  },
  {
    image: require('./../images/2.0.jpg'),
    desc: `
      <div class="fzlc-title">2019年</div>
      <div>优化油田公司</div>
      <div>组织管理架构</div>
      <div>大幅实现降本增效</div>
      <div>工业物联网跨行业平台</div>
      <div>供热</div>
      <div>工厂</div>
      <div>...</div>
    `,
    version: 'AIRIOT2.0'
  },
  {
    image: require('./../images/3.0.jpg'),
    desc: `
      <div class="fzlc-title">2021年</div>
      <div>持续深耕工业物联领域</div>
      <div>开拓智慧物联领域</div>
      <div>开始运用AI算法落地项目</div>
      <div>煤炭</div>
      <div>园区</div>
      <div能源div>
      <div>油田</div>
      <div>油库</div>
      <div>...</div>
    `,
    version: 'AIRIOT3.0'
  },
  {
    image: require('./../images/4.0.jpg'),
    desc: `
      <div class="fzlc-title">2023年</div>
      <div>产品底层架构升级</div>
      <div>五大能力引擎全面升级</div>
      <div>率先接入AI应用</div>
      <div>更加丰富的算法模型库</div>
      <div>...</div>
      <div class="fzlc-title">2024年</div>
      <div>升级AI算法能力，凭借平台强大的AI算法模型和丰富的垂直行业算法库，为物联网项目提供了基于AI算法模型的智能化的解决方案</div>
    `,
    version: 'AIRIOT4.0'
  }
] 

const conactData = [ 
  {
    image: require('./../images/总部.jpg'),
    title: '集团总部',
    address: '北京市丰台区海鹰路6号院2号楼',
    // mapUrl: 'https://map.baidu.com/poi/%E8%88%AA%E5%A4%A9%E7%A7%91%E6%8A%80%E6%8E%A7%E8%82%A1%E9%9B%86%E5%9B%A2%E8%82%A1%E4%BB%BD%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@12945368.966742711,4814203.51,19z?uid=736284b9389fedb335b06cc8&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl'
    mapUrl: 'https://map.baidu.com/search/%E5%8C%97%E4%BA%AC%E5%B8%82%E4%B8%B0%E5%8F%B0%E5%8C%BA%E6%B5%B7%E9%B9%B0%E8%B7%AF6%E5%8F%B7%E9%99%A22%E5%8F%B7%E6%A5%BC/@12945231.716742711,4814003.76,19z?querytype=s&da_src=shareurl&wd=%E5%8C%97%E4%BA%AC%E5%B8%82%E4%B8%B0%E5%8F%B0%E5%8C%BA%E6%B5%B7%E9%B9%B0%E8%B7%AF6%E5%8F%B7%E9%99%A22%E5%8F%B7%E6%A5%BC&c=131&src=0&pn=0&sug=0&l=16&b=(12954649.244652493,4835856.254538021;12959674.508364676,4838452.105461978)&from=webmap&biz_forward=%7B%22scaler%22:2,%22styles%22:%22pl%22%7D&device_ratio=2',
    description: `
      <div class="conact-us-desc-box">
        <div class="conact-us-support">官方支持</div>
        <div class="conact-us-zx conact-us-zx1">产品咨询：<a href="tel:010-84860985" class="tel">010-84860985</a></span></div>
      </div>
    `
  },
  {
    image: require('./../images/事业部.jpg'),
    title: '智慧物联事业部',
    address: '北京市朝阳区小关街道渔阳置业大厦B座7层',
    mapUrl: 'https://map.baidu.com/poi/%E6%B8%94%E9%98%B3%E7%BD%AE%E4%B8%9A%E5%A4%A7%E5%8E%A6/@12959390.97872933,4836314.580126156,16.37z?uid=92631f8465e36c50bca8221f&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl',
    description: `
      <div class="row row-cols-lg-2 row-cols-md-2 row-cols-1">
        <div class="col-lg-6 col-md-6 col-sm-12 col">
          <img class="mapImage" src="${require('./../images/公众号.jpg')}"/>
          <div>微信公众号</div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col">
          <img class="mapImage" src="${require('./../images/小助手.jpg')}"/>
          <div>客服小助手</div>
        </div>
      </div>
    `
  }
]

const cpysData = [
  {
      title: '节省成本',
      url: require('./../images/丁先生.png'),
      desc: '采用低代码的方式，减少了开发成本和时间，用户可以快速构建出符合自己需求的系统。'
  },
  {
      title: '高效协作',
      url: require('./../images/李女士.png'),
      desc: '支持多人协作，可以快速分配任务、跟踪进度并进行反馈，提高团队协作效率。'
  },
  {
      title: '灵活拓展',
      url: require('./../images/徐先生.png'),
      desc: '用户根据自己的业务需求进行自定义和配置，满足用户的个性化需求和特殊业务场景。'
  },
  {
      title: '自主可控',
      url: require('./../images/黄先生.png'),
      desc: '自主研发自主可控，拒接系统割裂和数据不互通的弊端确保了系统的稳定性和可靠性。'
  },
  {
      title: '数据安全',
      url: require('./../images/王女士.png'),
      desc: '采用了先进的安全技术和措施，保障数据的安全可靠，让用户无后顾之忧。'
  },
  {
      title: '长期保障',
      url: require('./../images/闫先生.png'),
      desc: '大项目验证，完善的服务体系，央企背书，成为用户更加可靠的合作伙伴。'
  }
]

const hyrkData = [
  {
    title: '产品奖项',
    desc: `
      <div>
        <p class="sector-desc">AIRIOT 荣获2024年度中国物联网行业创新产品榜</p>
        <p class="sector-desc">AIRIOT 荣获2022年“物联网行业年度评选最佳创新产品奖”</p>
        <p class="sector-desc">AIRIOT 荣获2021年“AIoT 新维奖最佳产品奖”</p>
        <p class="sector-desc">AIRIOT 连续两届荣获“IOTE 创新产品金奖”</p>
      </div>
    `
  },
  {
    title: '入选优秀企业',
    desc: `
    <div>
      <p class="sector-desc">AIRIOT 荣获2023年“物联之星”年度榜单之中国物联网企业100强</p>
      <p class="sector-desc">荣获“2023物联网场景应用品牌企业”奖项</p>
    </div>
  `
  },
  {
    title: '优秀行业案例',
    desc: `
      <div>
        <p class="sector-desc">AIRIOT 能源管理系统荣获 2022 年“物联网行业年度评选智慧能源应用方案奖”</p>
        <p class="sector-desc">AIRIOT 入选 2022 年“世界物联网新技术新产品新应用”年度成果</p>
        <p class="sector-desc">被评为物联网创新基础设施类项目</p>
        <p class="sector-desc">AIRIOT 能源管理解决方案荣获 2021 年“数字科技创新应用优秀成果奖”</p>
      </div>
    `
  },
  {
    title: '入选行业图谱',
    desc: `
      <div>
        <p class="sector-desc">AIRIOT 入选 2023 年【中国 AIoT 产业全景图谱】工业厂商平台</p>
        <p class="sector-desc">AIRIOT 入选 2022 年《中国物联网平台产业研究报告——物联网平台玩家图谱》</p>
        <p class="sector-desc">AIRIOT 入选 2022 年【中国 AIoT 产业全景图谱】新锐企业平台</p>
      </div>
    `
  }
]

export default () => {
  const [ aboutHash, setAboutHash ] = React.useState(null)
  const [ initPerView, setPerView ] = React.useState(4)
  const [ agents, setAgents ] = React.useState(true)
  const [ width, setWidth ] = React.useState(false)
  const [ row, setRow ]  = React.useState(false)
  const [swipers, setSwipers] = React.useState(null)
  const [activeIndex, setActiveIndex] = React.useState(1)

  React.useEffect(() => {
    let width = document.body.clientWidth

    bdstatistics()
    mqStatistics()

    if(!check()) {
      setPerView(1)
    } else {
      if(width <= 400) {
        setPerView(1)
      } else if(width <= 768 && width > 400) {
        setPerView(2)
      } else if (width <= 1100 && width > 768 ) {
        setPerView(3)
      } else if (width <= 1200 && width > 1100 ) {
        setPerView(4)
      } else if (width <= 1440 && width > 1200 ) {
        setPerView(3)
      } else if (width <= 1920 && width > 1440) {
        setPerView(4)
      } else {
        setPerView(5)
      }
    }
    if(typeof window !== "undefined") {
      if(window.location.hash && window.location.hash.indexOf('#') >-1)  {
        setAboutHash(window.location.hash.substr(window.location.hash.indexOf('#')+1))
      }

      let width = document.body.clientWidth
      if(width < 992) {
        setWidth(true)
      } else {
        setWidth(false)
      }
      if(width <=1024) {
        setRow(true)
      }

      window.addEventListener('resize', (event) => {
        let width = document.body.clientWidth
        if(width <=1024) {
          setRow(true)
        }

        if(width < 992) {
          setWidth(true)
        }else {
          setWidth(false)
        }


        if(width <= 400) {
          setPerView(1)
        } else if(width <= 768 && width > 400) {
          setPerView(2)
        } else if (width <= 1100 && width > 768 ) {
          setPerView(3)
        } else if (width <= 1200 && width > 1100 ) {
          setPerView(4)
        } else if (width <= 1440 && width > 1200 ) {
          setPerView(3)
        } else if (width <= 1920 && width > 1440) {
          setPerView(4)
        } else {
          setPerView(5)
        }
      })
    
    }
    setAgents(check())


  }, [])

  React.useEffect(() => {
    if(typeof window !== "undefined") {
      // 跳转到指定位置
      let product_media = document.getElementsByClassName('about-us-connext');
      if(aboutHash) {
        for(let i=0; i<product_media.length;i++) {
          let name = product_media[i].getAttribute('name')
          if(name == aboutHash) {
            window.scrollTo({
              top: product_media[i].offsetTop - 100
            })
          }
        }
      }
    }
  }, [aboutHash])

  const peopleData = [
    {
      peopleHost: require('./../images/about-people1.jpg'),
      title: '丁经理',
      desc: '工业互联网行业专家，熟悉能源管理、设备运维、智慧医药、智慧管廊等领域。',
      wxImg: require('./../images/about-wx1.jpg'),
      desc1: '从业时间：6年',
      desc2: '负责客户：100+家',
      desc3: '所获荣誉：工业互联网专家',
      desc4: '联系方式：扫描二维码'
    }
    // {
    //   peopleHost: require('./../images/about-people4.jpg'),
    //   title: '何经理',
    //   desc: '工业互联网行业精英，熟悉智慧城市、智慧油田、智慧工厂等领域。',
    //   wxImg: require('./../images/about-wx3.jpg'),
    //   desc1: '从业时间：4年',
    //   desc2: '负责客户：60+家',
    //   desc3: '所获荣誉：工业互联网专家',
    //   desc4: '联系方式：扫描二维码'
    // }
  ]

  const worthData = [ '自主创新', '能力协同', '开放合作', '互利共赢' ]

  const contactData = [
    {
      peopleHost: require('./../images/about-people1.jpg'),
      name: '丁先生',
      title: '北京总部',
      wxImg: require('./../images/微信1.png'),
      desc: '产品咨询',
      address: '北京市丰台区海鹰路6号院2号楼',
      tel: '010-84860985'
    },
    {
      peopleHost: require('./../images/about-people1.jpg'),
      name: '李女士',
      title: '沈阳办事处',
      wxImg: require('./../images/微信2.png'),
      desc: '产品咨询',
      address: '沈阳市浑南区新隆街18号沈阳美景新天地'
    },
    {
      peopleHost: require('./../images/about-people1.jpg'),
      name: '闫先生',
      title: '西安办事处',
      wxImg: require('./../images/微信3.png'),
      desc: '产品咨询',
      address: '西安市雁塔区乐和公馆'
    },
    {
      peopleHost: require('./../images/about-people1.jpg'),
      name: '王女士',
      title: '成都办事处',
      wxImg: require('./../images/微信4.png'),
      desc: '产品咨询',
      address: '成都市高新南区天府大道北段1700号环球中心'
    },
    {
      peopleHost: require('./../images/about-people1.jpg'),
      name: '徐先生',
      title: '苏州办事处',
      wxImg: require('./../images/微信5.png'),
      desc: '产品咨询',
      address: '苏州人工智能产业创新中心'
    },
    {
      peopleHost: require('./../images/about-people1.jpg'),
      name: '黄先生',
      title: '武汉办事处',
      wxImg: require('./../images/微信6.png'),
      desc: '产品咨询',
      address: '武汉市江岸区绿地汉口中心'
    }
  ]

  const getHeader = (width) => {
    return  width ? <MobileHeader /> : <Header />
  }

  const slideRef = React.useRef(null);
  const [calculatedWidth, setCalculatedWidth] = React.useState(null);

  React.useEffect(() => {
    if (slideRef.current) {
      const slideWidth = slideRef.current.offsetWidth;
      setCalculatedWidth(slideWidth);
    }
  }, []);
  
  return (
    <>
      <Consult />
      <div className="product-page about-page">
      <div className="home-index home-nav-fix">
        {/* <Header /> */}
        { getHeader(width) }
      </div>
        {/* <div className="home-bg" style={{ backgroundImage:`url(${require("./../images/about-banner1.png")})` }}>
          <Layout typePage="about" banner={true}> 
            <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
            <div style={{ display: 'flex' }}>
              <div className="plat-jumbotron" style={{ textAlign: 'center', margin: '0 auto' }}>
                <h1 className="plat-title">我们擅长把复杂的事情搞简单</h1>
              </div>
            </div>
          </Layout>
        </div> */}
        <span className="product-price about-home">
           <span className="product-price engine-home-4 tg-index-page">
            <div className="home-bg home-pref" style={{ position: 'relative', height: '34.375rem', backgroundImage:`url(${require("./../images/about-banner1.jpg")})`,
              backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}>
                <Layout typePage="home">
                    <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
                    <div className="producePrice-plat">
                        <div className="plat-jumbotron">
                            <div className="">
                                <div className="jumbotron-title-common" style={{ marginTop: '8.375rem', fontSize: agents ? '3.25rem' : '2rem', color: 'rgba(18, 20, 28, 0.9)' }}>
                                人人都能用的<span className="zhxt">智慧系统</span>搭建平台
                                </div>
                                <div className="jumbotron-index-desc jumbotron-desc-common jumbotron-desc-common-home" style={{ marginTop: 64 }}>以软件构建智慧物联基础设施建设</div>
                                <div className="jumbotron-index-desc jumbotron-desc-common jumbotron-desc-common-home" style={{ marginTop: 14 }}>让物联网项目更简单</div>                              
                            </div>
                        </div>
                    </div>
                </Layout>
            </div>
          </span>
         </span>
        <div className="container about-content">
          <CommonFlex style={{ marginTop: '3.125rem' }} title="关于AIRIOT"></CommonFlex>
          <Row lg={2} md={2} xs={1} className="about-airiot-row">
            {/* <Col className="about-left-air">
              AIRIOT是物联网平台开发框架产品， 由智慧物联事业部历时10年倾力打造，致力于低代码搭建IOT平台，持续将业务抽象成通用的模块、组件、表单等，并经过项目应用实践沉淀和持续优化，形成了一款拥有完全自主知识产权、图形化开发、全面快速感知、快速构建不同行业应用、开放的物联网平台。
            </Col> */}
            <Col className="about-left-air col-lg-7 col-md-7 col-sm-12">
              <img src={require('./../images/关于.png')} />
            </Col>
            <Col className="about-right-air col-lg-5 col-md-5 col-sm-12">
              {/* <div className="about-airiot">关于AIRIOT</div>
              <div className="about-airiot-line"></div> */}
              <div>AIRIOT智慧系统搭建平台致力于打造数字经济发展和新质生产力的数字基座，以软件构建智慧物联场景的基础设施建设。</div>
              <div className="about-airiot-title-s">核心五大能力引擎包括数据采集与控制引擎、三维可视化组态引擎、数据分析引擎、业务流引擎、二次开发引擎，满足80%以上的智慧项目应用。</div>
              <div className="about-airiot-title-s">服务领域覆盖智慧园区、智慧楼宇、智慧能源、智慧水务、智慧光伏、数字化工厂等40+行业，帮助用户快速搭建自主可控的行业物联网系统。</div>
            </Col>
          </Row>
        </div>
        <div className="about-bg-div-fzlc">
          <div className="container about-content"> 
          <CommonFlex title="发展历程" style={{ paddingTop: '3.125rem', marginTop: '3.125rem' }}></CommonFlex>


            <Swiper
              loop={true}
              className="about-swiper"
              autoplay={{ delay: 3000 }}
              style={{ padding: '0 0 60px 0' }}
              slidesPerView={1}
              initialSlide={4}
              centeredSlides={true}
              lazy={{
                loadPrevNext: true,
                loadPrevNextAmount: 20,
              }}
              onSwiper={(swiper) => {
                setSwipers(swiper)
                swiper.$el[0].addEventListener('mouseover', function() { swiper.autoplay.stop()});
                swiper.$el[0].addEventListener('mouseleave',()=>swiper.autoplay.start());
              }}
              onSlideChange={(swiper) => { 
                if (swiper.activeIndex == fzlcData?.length + 1) { setActiveIndex(1) } else { setActiveIndex(swiper.activeIndex) } 
              }}
            > 
              {
                fzlcData.map(item => {
                  return <SwiperSlide>
                    <div className="conact-us-fzlc">
                      <div className="conact-us-fzlc-left"><img src={item.image} alt="" /></div>
                      <div className="conact-us-fzlc-right" dangerouslySetInnerHTML={{__html: item?.desc}}></div>
                    </div>
                  </SwiperSlide>
                })
              }
            </Swiper>
            <Row className="conact-us-point-box">
              {
                fzlcData.map((item, index) => {
                  return <Col><div onClick={() => swipers.slideToLoop(index, 1000, false)} onMouseLeave={()=> swipers.autoplay.start()}>
                    <div className={activeIndex-1 == index ? "conact-us-circle conact-us-circle-active" : "conact-us-circle"}>
                      <div className="conact-us-circle-div"></div>
                    </div>
                    <div className={activeIndex-1 == index ? "conact-us-point conact-us-active" : "conact-us-point"}>{item?.version}</div>
                  </div></Col>
                })
              }
            </Row>
            {/* <div style={{ paddingTop: 10 }}>
              <div className="about-airiot">发展历程</div>
              <div className="about-airiot-line"></div>
            </div>
            <div className="fzlc">
              <div className="fzlc-div">AIRIOT在工业领域和智慧物联领域深耕10年服务上千个物联项目，持续将业务抽象成通用的模块、组件、表单等，并经过项目应用实践沉淀和持续优化，形成了一套自主研发自主可控、全面国产化的智慧系统搭建平台，通用型适用于各个行业的智慧项目。</div>
              <img className="fzlc-img" src={require('./../images/发展历程.png')}/>
            </div> */}
          </div>
        </div>
        <div className="container about-content"> 
          <CommonFlex title="产品优势" desc="使用AIRIOT的智慧系统搭建平台，用户在项目交付将体会到无限爽感" style={{ marginTop: '3.125rem' }}></CommonFlex>
          <Row xs={1} lg={6} md={3} className="connect-us-product">
            {
              cpysData.map(item => {
                return (
                  <Col>
                    <Card className="connect-product-card">
                      <div className="product-title">{item.title}</div>
                      <div className="product-desc">{item.desc}</div>
                    </Card>
                    <div className="connect-circle">
                      <span></span>
                    </div>
                  </Col>
                )
              })
            }
          </Row>
        </div>
        <div className="about-bg-div">
          <div className="container about-content">  
            <CommonFlex title="行业认可" style={{ marginTop: '3.125rem', paddingTop: '3.125rem' }}></CommonFlex>
            <Row xs={1} lg={2} md={2} className="connect-us-sector">
              {
                hyrkData.map(item => {
                  return (
                    <Col>
                      <Card className="connect-sector-card">
                        <div className="sector-title">{item.title}</div>
                        <div className="sector-line"></div>
                        <div className="sector-desc-box" dangerouslySetInnerHTML={{ __html: item.desc }}></div>
                      </Card>
                    </Col>
                  )
                })
              }
            </Row>
          </div>    
          {/* <Swiper
            loop={true}
            className="about-swiper"
            autoplay={{ delay: 3000 }}
            style={{ padding: '50px 0 60px 0' }}
            slidesPerView={initPerView}
            centeredSlides={true}
            spaceBetween={10}
            lazy={{
              loadPrevNext: true,
              loadPrevNextAmount: 20,
            }}
            onSwiper={(swiper) => {
              swiper.$el[0].addEventListener('mouseover', function() { swiper.autoplay.stop()});
              swiper.$el[0].addEventListener('mouseleave',()=>swiper.autoplay.start());
            }}
            onSlideChange={() => console.log('slide change')}
          >
            <SwiperSlide className="ry ry4"><img src={require('./../images/ry4.jpg')} alt="" /></SwiperSlide>
            <SwiperSlide className="ry ry1"><img src={require('./../images/ry1.jpg')} alt="" /></SwiperSlide>
            <SwiperSlide className="ry ry2"><img src={require('./../images/ry2.jpg')} alt="" /></SwiperSlide>
            <SwiperSlide className="ry ry5"><img src={require('./../images/ry5.jpg')} alt="" /></SwiperSlide>
            <SwiperSlide className="ry ry3"><img src={require('./../images/ry3.jpg')} alt="" /></SwiperSlide>
            <SwiperSlide className="ry ry6"><img src={require('./../images/ry6.jpg')} alt="" /></SwiperSlide>
            <SwiperSlide className="ry ry7"><img src={require('./../images/ry7.jpg')} alt="" /></SwiperSlide>
            <SwiperSlide className="ry ry8"><img src={require('./../images/ry8.jpg')} alt="" /></SwiperSlide>
            <SwiperSlide className="ry ry9"><img src={require('./../images/ry9.jpg')} alt="" /></SwiperSlide>
            <SwiperSlide className="ry ry10"><img src={require('./../images/ry10.jpg')} alt="" /></SwiperSlide>
          </Swiper> */}
          <div className="about-swiper-box" style={{ padding: '50px 0 60px' }} >
            <div className="ecology-ul">
                <div className="ecology-div" style={{ animation: 'zhong1 60s linear infinite normal' }}>
                  <div className="ry ry4"><img src={require('./../images/ry4.jpg')}/></div>
                  <div className="ry ry1"><img src={require('./../images/ry1.jpg')}/></div>
                  <div className="ry ry2"><img src={require('./../images/ry2.jpg')}/></div>
                  <div className="ry ry5"><img src={require('./../images/ry5.jpg')}/></div>
                  <div className="ry ry3"><img src={require('./../images/ry3.jpg')}/></div>
                  <div className="ry ry6"><img src={require('./../images/ry6.jpg')}/></div>
                  <div className="ry ry7"><img src={require('./../images/ry7.jpg')}/></div>
                  <div className="ry ry8"><img src={require('./../images/ry8.jpg')}/></div>
                  <div className="ry ry9"><img src={require('./../images/ry9.jpg')}/></div>
                  <div className="ry ry10"><img src={require('./../images/ry10.jpg')}/></div>
                </div>
                <div className="ecology-div" style={{ animation: 'zhong2 60s linear infinite normal' }}>
                  <div className="ry ry4"><img src={require('./../images/ry4.jpg')}/></div>
                  <div className="ry ry1"><img src={require('./../images/ry1.jpg')}/></div>
                  <div className="ry ry2"><img src={require('./../images/ry2.jpg')}/></div>
                  <div className="ry ry5"><img src={require('./../images/ry5.jpg')}/></div>
                  <div className="ry ry3"><img src={require('./../images/ry3.jpg')}/></div>
                  <div className="ry ry6"><img src={require('./../images/ry6.jpg')}/></div>
                  <div className="ry ry7"><img src={require('./../images/ry7.jpg')}/></div>
                  <div className="ry ry8"><img src={require('./../images/ry8.jpg')}/></div>
                  <div className="ry ry9"><img src={require('./../images/ry9.jpg')}/></div>
                  <div className="ry ry10"><img src={require('./../images/ry10.jpg')}/></div>
                </div>
            </div>
          </div>  
        </div>  
        <div className="container about-content about-us-connext" name="connect">  
          <CommonFlex title="联系我们" style={{ marginTop: '3.125rem' }}></CommonFlex>
          {/* <Row lg={2} md={2} xs={1} className="about-airiot-row">
            <Col className="connect-us-left col-lg-5 col-md-5 col-sm-12">
              <div>集团总部：北京市丰台区海鹰路6号院2号楼</div>
              <div>产品咨询：010-84860985</div>
              <div>产品官网：airiot.cn</div>
            </Col>
            <Col className="col-lg-2 col-md-2 col-sm-0"></Col>
            <Col className="connect-us-right col-lg-5 col-md-5 col-sm-12">
              <Row>
                {
                  wxData.map(item => {
                    return (
                      <Col>
                        <img className="connect-wx-img" src={item?.url}/>
                        <div className="connect-wx-title">{item?.title}</div>
                      </Col>
                    )
                  })
                }
              </Row>
            </Col>
          </Row> */}
          {/* <Row lg={1} md={1} xs={1} className="about-airiot-row">
            <img src={require('./../images/地图.png')} />
          </Row> */}
          {/* <Row xs={1} lg={3} md={2} className="connect-us-hexagon">
            {
              xmData.map(item => {
                return (
                  <Col>
                    <Card className="connect-hexagon-card">
                      <div className="hexagon-title">{item.title}</div>
                      <div className="hexagon-desc-box">
                        <div className="hexagon-desc">{item.desc}</div>
                        <div>
                          <img src={item?.url}/>
                        </div>
                      </div>
                    </Card>
                  </Col>
                )
              })
            }
          </Row> */}
          <Row xs={1} lg={2} md={2} className="conact-us-row">
            {
              conactData.map(item => {
                return (
                  <Col className="conact-col">
                    <Card className="conact-us-card-t">
                      <div className="sector-title">{item?.title}</div>
                      <div className="conact-us-t-image">
                        <img src={item?.image}/>
                      </div>
                      <div className="conact-us-address">
                        <div>{item.address}</div>
                        <div><a href={item?.mapUrl} target="_blank">{'查看地图' + '>' }</a></div>
                      </div>
                    </Card>
                    {/* <Card className="conact-us-card">
                      <div className="hexagon-desc-box" dangerouslySetInnerHTML={{ __html: item.description }}></div>
                    </Card> */}
                  </Col>
                )
              })
            }
          </Row>
          <Row xs={1} lg={2} md={2} className="conact-us-row">
            {
              conactData.map(item => {
                return (
                  <Col className="conact-col">
                    <Card className="conact-us-card">
                      <div className="hexagon-desc-box" dangerouslySetInnerHTML={{ __html: item.description }}></div>
                    </Card>
                  </Col>
                )
              })
            }
          </Row>
        </div> 
        <div className="tl-banner">
          <div className="tl-banner-title">找对伙伴轻松一半，马上开启AIRIOT物联之旅</div>
          <Link to="https://r8ja3mlg7i.feishu.cn/share/base/form/shrcn6Ns5Vk4fgiEanpunuN71Ve" target="_blank"><Button>开始试用</Button></Link>
        </div>
        <Footer></Footer>
      </div>   
    </>
  )
}  

