import React from "react"
import { Link } from 'gatsby'
import Breadcrumb from 'react-bootstrap/Breadcrumb'

const BreadCrumb = props => {
  
  return (
    <Breadcrumb className="bread-box" style={{ ...props.style, margin: '2rem 0' }}>
      <Breadcrumb.Item className="prev-bread"><Link to="/">首页</Link></Breadcrumb.Item>
      { 
        props.flag ? <Breadcrumb.Item className="prev-bread"><Link to={props.href}>{props.title}</Link></Breadcrumb.Item> : null
      }
      <Breadcrumb.Item active>详情</Breadcrumb.Item>
    </Breadcrumb>
  )
}

export default BreadCrumb
